import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import "./blog-post.scss";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { useLocation } from "@reach/router";
import { SloganFlipper } from "../components/slogan/slogan-flipper";

export default function Template({ data }) {
    const { markdownRemark: post } = data;
    
    let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;
    let authorImgFluid = post.frontmatter.authorImage.childImageSharp.fluid;

    // const { site } = useStaticQuery(data);

    const { pathname } = useLocation();
    // const {
    //     siteUrl,
    // } = site.siteMetadata;
    
    let disqusConfig = {
        url: `https://negligenciasdeabogados.net/${pathname}`,
        identifier: post.id,
        title: post.frontmatter.title,
        }

    return (
        <React.Fragment>
            <SEO title="Blog"/>
            <Layout pageId="blog-post">
                <div className="blog-post-container">
                    <Helmet
                        title={`${post.frontmatter.title}`}
                    />
                    <div className="blog-post">
                        {/* <Img fluid={featuredImgFluid} className="featured-image"/> */}
                        <CommentCount config={disqusConfig} placeholder={'...'} />


                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        />

                        {/* <Img fluid={authorImgFluid} className="author-image"/> */}
                        <h4>
                            {/* Redactado por experto {post.frontmatter.author} */}
                            {/* <a target="_blank" href={post.frontmatter.authorLink}>{post.frontmatter.author}</a> */}
                        </h4>
                        <h5 className="date">
                        Redactado a {post.frontmatter.date}

                        <SloganFlipper></SloganFlipper>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Disqus config={disqusConfig} />
                        </h5>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        siteUrl: url
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY", locale: "es")
        path
        title
        author
        authorLink
        authorImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
